<template>


	<div id="toolspages " class="m-2">

		<a href="/s3tool">Home</a> <span v-if="orgid != ''">/</span> <a v-if="orgid != ''" :href="'/s3tool?orgid=' + orgid">Org</a>

		<div style="position: fixed; top: 20px; right: 10px;">
			<input type="checkbox" v-model="dontreplace" > Don't replace
		</div>


		<b-list-group class="w-50" v-if="boardid=='' && orgid == ''"> 
			<template v-for="(item,key) in pages">
		  <b-list-group-item :key="key"> <a :href="'/s3tool?orgid='+item.id">{{ item.label }} - {{ item.data1 }} users</a> </b-list-group-item>
		</template> 
		</b-list-group>

		<template v-if="boardid=='' && orgid != ''">
		<b-list-group class="w-50" > 
			<template v-for="(item,key) in pages">
		  <b-list-group-item :key="key"> <a :href="'/s3tool?orgid='+orgid+'&boardid='+item.id">{{ item.label }} - {{ item.date }}</a> </b-list-group-item>
		</template> 
		</b-list-group>


		<button class="mt-2" type="button" @click="markdone()" v-if="orgid!='' && !working">Done with this org</button>
		<button class="mt-2" type="button" @click="markskip()" v-if="orgid!='' && !working && 1==2">Skip this org</button>
		</template>


		<div v-else>

		<div v-if="1==2">
		<a href="#" @click="parseimages()">Run</a>
		<template v-for="(image,key) in imagelist">
			<div :key="'list_'+key" v-if="image.image.src.includes('eu-west')"><img :src="image.image.src"></div>
		</template>
		</div>

			

		<template v-for="(item,key) in pages">
		<div :key="key" class="w-50 mb-2">
			<b-card v-if="item.indicatordate" :title="indicatordate(item.indicatordate) + ' ' + ( item.measuretype ? item.measuretype : '' )">

				<div  class="statuspost border p-2 mt-2 mb-2" :id="item._id" >
					<div :ref="'indicator_'+item._id" v-html="item.message" @click="findimageandfix($event, item, 'indicator')"></div>

					<button v-if="!working" class="mt-2" type="button" @click="savetext(item, 'indicator')" >Save status</button>
					<button v-else class="mt-2" type="button" disabled="disabled" >Save status</button>
				</div>
			</b-card>

			<b-card v-if="item.measuretype=='Objective' && leng(item.description) > 10" :title="item.measuretype">

				<div  class="statuspost border p-2 mt-2 mb-2" :id="item.id" >
					<div :ref="'objective_'+item.id" v-html="item.description" @click="findimageandfix($event, item, 'objective')"></div>

					<button v-if="!working" class="mt-2" type="button" @click="savetext(item, 'objective')" >Save objective</button>
					<button v-else class="mt-2" type="button" disabled="disabled" >Save objective</button>
				</div>

			</b-card>

			<b-card v-if="item.type=='Report'" :title="item.type">

				<div  class="statuspost border p-2 mt-2 mb-2" :id="item._id" >
					<div :ref="'report_'+item._id" v-html="item.contents" @click="findimageandfix($event, item, 'report')"></div>

					<button v-if="!working" class="mt-2" type="button" @click="savetext(item, 'report')" >Save report</button>
					<button v-else class="mt-2" type="button" disabled="disabled" >Save report</button>
				</div>

			</b-card>

			<b-card v-if="item.type=='Project' && leng(item.description) > 10" :title="item.type">

				<div  class="statuspost border p-2 mt-2 mb-2" :id="item._id" >
					<div :ref="'project_'+item._id" v-html="item.description" @click="findimageandfix($event, item, 'project')"></div>

					<button v-if="!working" class="mt-2" type="button" @click="savetext(item, 'project')" >Save report</button>
					<button v-else class="mt-2" type="button" disabled="disabled" >Save project</button>
				</div>

			</b-card>

			<b-card v-if="item.type=='Organization' && leng(item.description) > 10" :title="item.type">

				<div  class="statuspost border p-2 mt-2 mb-2" :id="item._id" >
					<div :ref="'organization_'+item._id" v-html="item.description" @click="findimageandfix($event, item, 'organization')"></div>

					<button v-if="!working" class="mt-2" type="button" @click="savetext(item, 'organization')" >Save organization</button>
					<button v-else class="mt-2" type="button" disabled="disabled" >Save project</button>
				</div>

			</b-card>


		</div>


		</template>

		
	</div>
	</div>

</template>

<style>
.nav-link, .nav-link:active, .nav-link:visited, .nav-link:focus{
	font: #fff !important;
}
</style>
<script type="text/javascript">
	
</script>
<script>

	import axios from 'axios'
	import moment from 'moment';

	import AWS from 'aws-sdk'

	import Cockpitsapi from '@/services/api/cockpits'

	const ID = process.env.VUE_APP_CLIENT;
	const SECRET = process.env.VUE_APP_CRET;

	// The name of the bucket that you have created
	const BUCKET_NAME = 'floweimage';
	AWS.config.update({region: 'eu-west-3'});

	const s3Bucket = new AWS.S3({
		accessKeyId: ID,
		secretAccessKey: SECRET,
		apiVersion: '2006-03-01', 
		region: 'eu-west-3',
		params: {Bucket: BUCKET_NAME,ACL : 'public-read',}
	});

	export default{
		name:'items',
		 data(){
		     return{
		     	imagelist:[],
		     	dontreplace:false,
		     	working:false,
		     	pages:[],
				vardump:null,
				froalaconfig: {},
				linkInitControls:{},
				editstatusbody:false,
				monthnow:1,
				monthmax:1,
				boardurl:"",
				boardurlparts:{},
				boardid:"",
				orgid:"",
				updatedreports:{}
		     }
		 },
		watch:{
			//$route (to, from){
			//	this.getlinksbyboard( this.$route.query.boardid )
			//}
		},
		mounted() {
			
			let self = this

			window.fiximage = function( data , btn ){

			 	self.fiximage(data, btn)
			}
			
			if(this.$route.query.month){
				this.monthnow =  this.$route.query.month 
			}	

			let boardid = ""		

			if(this.$route.query.boardid){
				self.boardid =  this.$route.query.boardid 
			}

			if(this.$route.query.orgid){
				self.orgid =  this.$route.query.orgid 
			}

			//if(this.$route.query.month){
			//	this.monthnow =  this.$route.query.month 
			//}
			
			

			// axios.get( 'http://localhost:8081/Tools/Getalllinks?prev=2' ).then( function( response ){

				
			// 	// setTimeout(() => {
			// 	// 	self.bindimgs()
			// 	// }, 300);
			// })

			// this.setupfroala( function(){

			// 	Cockpitsapi.Getalllinks( self.boardid, self.token ).then( function(response) {
			// 		self.pages = response.data.links
			// 	})

			// })

			Cockpitsapi.Getalllinks( self.orgid, self.boardid, self.token ).then( function(response) {
					self.pages = response.data.links

					setTimeout( function(){
						self.bindimgs()
					},500)
					
				})
		},
		methods:{

			parseimages(){

				let self = this;

				$('img').each( function( i ){
					self.imagelist.push( { key:i, image: this } )
				})

			},
			leng( txt ){

				return String(txt).length
			},

			markdone(){

				if( confirm( 'Done with '+ this.orgid + '?' ) ){

					Cockpitsapi.Markdone( this.orgid, this.token ).then( function(response) {

						location.href = '/s3tool'
					})
				}
			},

			findimageandfix( obj , item , ttype ){

				let self = this

				let div = obj

				if( this.dontreplace ){

					div.target.removeAttribute('style')
					div.target.removeAttribute('width')
					div.target.removeAttribute('height')
					return false
				}

				item.oldimage = div.target.src 
				div.target.src = ""
				//div.target.src = "https://floweimage.s3.eu-west-3.amazonaws.com/noimg64.png"

				

				this.fiximage( item , function( img , isnoimg ){

					if( isnoimg == true ){
						div.target.removeAttribute('style')
						div.target.removeAttribute('width')
						div.target.removeAttribute('height')
					}

					div.target.src = img
					item.newimage = img

					self.savetext(item, ttype)
				})

			},

			gohome(){

				let self = this

				Cockpitsapi.Getalllinks( '', self.token ).then( function(response) {
					self.boardid = ""
					self.pages = response.data.links
				})


			},

			openboard( board ){

				alert( board.id )

				this.getlinksbyboard( board.id )
			},

			getlinksbyboard( boardid ){

				let self = this
				Cockpitsapi.Getalllinks( boardid, self.token ).then( function(response) {
					self.pages = response.data.links
				})

			},

			loadindicator(){

				let self = this

				let u = this.boardurl.split('?')
				if( u[1]) {
					const searchParams = new URLSearchParams(u[1]);
					let iid = searchParams.get("iid")

					Cockpitsapi.Getalllinksbyindicator( iid , this.token ).then( function(response) {
						self.pages = response.data.links
					})
				}
				
			},

			prevlink(){
				return '/s3tool?month='+( parseInt(this.monthnow) - 1 );
			},
			nextlink(){
				return '/s3tool?month='+( parseInt(this.monthnow) + 1 );
			},

			monthname( monthnow ){

				const date = new Date();  // 2009-11-10
				let newDate = new Date(new Date(date).setMonth(date.getMonth() + (monthnow-1) ));

				const monthname = newDate.toLocaleString('default', { month: 'long' });
				return monthname
			},

			savetext( obj, type ){

				this.working = true

				let self = this
				//console.log( this.$refs['statuspost_'+indicator.id][0], this.$refs['statuspost_'+indicator.id][0].innerHTML )

				let newtext = this.$refs[ type + '_'+obj.id][0].innerHTML

				Cockpitsapi.Updatetext( { "what":type, "t":this.token, "newtext":newtext, "pid":obj.id, "iid":obj._id, "oid":obj.id, "nid":obj.id, "oldimage":obj.oldimage, "newimage":obj.newimage, "boardid":this.boardid } ).then( function(response){

					self.working = false

					if( response.data.ok ){

						//Cockpitsapi.Replacelinks( indicator._id, self.token ).then( function(response){
						//	self.updatedreports = response.data.newreports
						//})
						//console.log( response.data , "dosave")
					}
					
				})
			},

			uploadtos3( indicator , callback ){

				if( indicator.imagebase == "" ){
					return false;
				}

				let self = this

				var md5 = require('md5');
				let base64image = indicator.imagebase
				let nowstring = new Date().toString()
				let imagekey = "image" + nowstring

				var buf = Buffer.from( base64image.replace(/^data:image\/\w+;base64,/, ""),'base64')
				var data = {
				Key: md5( imagekey  ) + ".jpg" , 
				Body: buf,
				ContentEncoding: 'base64',
				ContentType: 'image/jpeg'
				};

				s3Bucket.putObject(data, function(err, data){
					if (err) { 
						
						console.log(err);
						console.log('Error uploading data: ', data); 
					
					} else {


						let graphimageurl = "https://s3-eu-west-3.amazonaws.com/floweimage/" + md5( imagekey  ) + ".jpg"

						indicator["imagesrc"] = graphimageurl


						if( callback ){
							callback( graphimageurl )
						}

					}
				})


			},

			savedescription(){

				if(this.linkInitControls){
					// this.cockpit.description = this.linkInitControls.getEditor().html.get()
					// this.editorstate.description.content = this.cockpit.description
					// this.cockpitupdate('description', this.cockpit.description ); 
					// this.editorstate.description.state='idle'	
				}

			},


			//initializefroala: function(initControls) {
			//	this.linkInitControls = initControls;
			//	this.linkInitControls.initialize()
			//},

			setupfroala( callback ){


			let self = this

			this.froalaconfig = self.$store.state.config

			$.getJSON( "https://s3token2.flowe.io/", function(data){

				self.froalaconfig.id = 'xasdaskasfjasfj'

				self.froalaconfig.toolbarSticky =  false
				self.froalaconfig.toolbarStickyOffset = 0   
				self.froalaconfig.heightMin = 350
				self.froalaconfig.heightMax = 0

				self.froalaconfig.requestWithCORS = false
				self.froalaconfig.imageCORSProxy = 'https://flowecors.flowe.io'

				self.froalaconfig.imageUploadToS3 = data
				self.froalaconfig.fileUploadToS3 = data  

				self.froalaconfig.placeholderText = 'Add a summary that lets everyone know what this is about.'

				let toolbar = ['undo', 'redo']
				self.froalaconfig.toolbarButtons.moreMisc.buttons = toolbar
				self.froalaconfig.toolbarButtons.moreMisc.buttonsVisible = 0


				self.froalaconfig.events =Object.assign(self.froalaconfig.events,
				{
					'blur':function(){

						if( self.onblursavedescription ){
						//	self.onblursavedescription()
						}
					}

				},

				{
				'image.beforeUpload': function (images, lo ) {
					self.imageuploading = true
				},

				'image.inserted': function ($img, response) {
					self.imageuploading = false
				},

				'file.beforeUpload': function (files, o, k) {
				},
				'file.uploaded': function (response) {
				  // File was uploaded to the server.
				   console.log( response, "uploaded")
				},
				'file.inserted': function ($file, response) {
				  // File was inserted in the editor.
				  console.log( $file, response, "inserted")
				},
				'file.error': function (error, response) {

				  console.log("error:" + error.code , response )
				}

				})

				if( callback ){
					callback()
				}
				


			})

			},

			indicatordate(indicator){
				// console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
				return moment(indicator).format("DD MMM, YYYY")
			},

			fiximage( indicator , callback ){
				let self = this
				Cockpitsapi.Indicatorimage( indicator._id, this.token ).then( function( response ){
					//alert( response.data.image )

					if( response.data.image && response.data.image != '' ){
						indicator.imagebase = response.data.image
						indicator.imagefound = true
						self.uploadtos3( indicator , callback )
					}else{
						if( callback ){
							callback( 'https://floweimage.s3.eu-west-3.amazonaws.com/noimg64.png', true )
						}
					}
					
				})
			},
			bindimgs(){


			},
			bindimgs2(){

				
				let self = this

				//$('img').off('error').on('error', function( obj ){
                //    obj.target.src = '/static/img/imgbroken.png'
                //    obj.target.style = ''
                //  })

				$('.statuspost').each( function( index,post ){

					let statuspostid = post.id

					$('img', post).each( function( key,img ){
					
						// $(img).off('error').on('error', function( obj ){
						// 	img.alt = 'this image is broken'
						// 	//img.src = img.src + '?' + Math.random()

						// 	$('<a href="#" class="border p-2 fixlink bg-danger" onclick="window.fiximage(\''+ statuspostid +'\', this);return false">Get image</a>').insertAfter(img)
						// })

						$(img).off('click').on('click', function( obj ){
							//console.log( obj.currentTarget )
							self.fiximage( statuspostid)
						})
						
					})
				})

				


			}
		},
		computed:{

			token(){
				return this.$store.getters.token
			},
		}

	}
</script>